@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import url(./button.css);
@import url(./navbar.css);
@import url(./input.css);
@import url(./card.css);

:root {
  --socraft-dark: #282828;
  --socraft-yellow: #fbbb10;

  --default-page-background: #f7f6f2;

  --white: #fff;
  --success: #62bd19;
  --danger: #ff0e0ed6;

  --box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 3px 0px;
  --border-color: #28282827;

  --uib-size: 60px;
  --uib-speed: 0.5s;
}

* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body {
  background: url("../images/background.svg") bottom fixed no-repeat,
    var(--default-page-background);
}

::selection {
  background-color: #fbbb1050;
}

.page-layer {
  margin: 10em 5em 10em 5em;
}

.page-layer header {
  margin: 0 auto;
  text-align: center;
}

.page-layer header .client-selector {
  display: flex;
  align-items: center;
  gap: 2em;
  width: max-content;
  margin: 0 auto;
  flex-wrap: wrap;
}

.page-layer header .client-selector .option {
  background-color: transparent;
  border: solid 1px var(--socraft-yellow);
  color: var(--socraft-yellow);
  width: max-content;
  margin: 0 !important;
}

.page-layer header .client-selector .option.active {
  background-color: var(--socraft-yellow);
  color: var(--socraft-dark);
}

.page-layer header h1 {
  font-size: 2.9em;
}

.content-layer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  margin: 3em auto 0 auto;
}

.content-layer .column.disabled {
  opacity: 0.5;
}

.content-layer .column {
  width: 49%;
  height: 100%;
}

.actions-layer {
  margin: 3em auto 0 auto;
}

.tables-layer {
  margin: 3em 0 0 0;
  width: 100%;
  gap: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 1665px) {
  .tables-layer {
    flex-direction: column;
    align-items: flex-start;
  }
}

table {
  border-spacing: 0;
  border: solid 2px var(--socraft-dark);
  background-color: var(--white);
}

table th, table td {
  border: solid 1px var(--socraft-dark);
  margin: 0;
}

table th {
  background-color: var(--socraft-dark);
  color: var(--socraft-yellow);
  padding: 0.5em 1.5em;
}

table td {
  padding: 0.75em 2em;
}

@media screen and (max-width: 1450px) {
  .content-layer {
    flex-direction: column;
    align-items: center;
  }

  .content-layer .column {
    width: 100%;
  }
}

@media screen and (max-width: 895px) {
  .page-layer header .client-selector {
    flex-direction: column;
  }

  .content-layer {
    width: 100%;
  }
}

.popup {
  width: 60%;
  border-radius: 40px;
}

@media screen and (max-width: 1125px) {
  .popup {
    width: 100%;
  }
}

.popup .popup-content {
  text-align: left;
  padding: 1em 3em;
}

.popup .popup-content h1 {
  font-size: 1.4em;
  color: var(--socraft-dark);
  font-weight: 700;
}

.popup .popup-content form {
  margin: 2em 0 0 0;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 1em;
  background-color: var(--white);
  padding: 12px 20px;
  border-radius: 15px;
  border: solid 1px var(--border-color);
}

.input-group input {
  border: none;
  background-color: transparent;
  font-size: 1em;
}
