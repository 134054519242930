.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.75em 2em;
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  z-index: 9999;
}

.navbar img {
  height: 50px;
  cursor: pointer;
}
