.card {
  background-color: var(--white);
  border-radius: 15px;
  box-shadow: var(--box-shadow);
  padding: 1em 4em 2em 4em;
  width: 100%;
}

.card:not(:first-child) {
  margin: 1em 0;
}

.card header h2 {
  font-size: 1.7em;
}
